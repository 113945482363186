import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Cover from '../../components/Cover/Cover';
import './Product.css';
import Footer from '../../components/Footer/Footer';
import product_cover from '../../assets/images/products/products_cover.jpg';
// import kitchen from '../../assets/images/products/kitchen.jpg';
// import cooking from '../../assets/images/products/cooking.jpg';
// import fnb from '../../assets/images/products/fnb.jpg';
// import housekeeping from '../../assets/images/products/housekeeping.jpg';
// import lineware from '../../assets/images/products/lineware.jpg';
// import paper from '../../assets/images/products/paper.jpg';
import leaf from '../../assets/images/products/leaf.png';
import leaf2 from '../../assets/images/products/leaf2.png';
import leaf3 from '../../assets/images/products/leaf3.png';
import leaf4 from '../../assets/images/products/leaf4.png';
import kitchen_img from '../../assets/images/products/kitchen/kitchen_main.jpg';
import cooking_img from '../../assets/images/products/cooking/cooking_main.jpg';
import fnb_img from '../../assets/images/products/fnb/fnb_main.jpg';
import housekeeping_img from '../../assets/images/products/housekeeping/housekeeping_main.jpg';
import lineware_img from '../../assets/images/products/lineware/lineware_main.jpg';
import paper_img from '../../assets/images/products/paper/paper_main.jpg';
import ProductCarousel from '../../components/ProductsCarousel/ProductCarousel';
import {
	product_img,
	product_img2,
	product_img3,
	product_img4,
	product_img5,
	product_img6
} from '../../components/data';

const Products = () => {
	const scrollToKitchen = () => {
		const kitchenSection = document.getElementById('kitchen_sec');
		kitchenSection.scrollIntoView({ behavior: 'smooth' });
	};
	const scrollToCooking = () => {
		const kitchenSection = document.getElementById('cooking_sec');
		kitchenSection.scrollIntoView({ behavior: 'smooth' });
	};
	const scrollToFnb = () => {
		const kitchenSection = document.getElementById('fnb_sec');
		kitchenSection.scrollIntoView({ behavior: 'smooth' });
	};
	const scrollToHousekeeping = () => {
		const kitchenSection = document.getElementById('housekeeping_sec');
		kitchenSection.scrollIntoView({ behavior: 'smooth' });
	};
	const scrollToLineware = () => {
		const kitchenSection = document.getElementById('lineware_sec');
		kitchenSection.scrollIntoView({ behavior: 'smooth' });
	};
	const scrollToPaper = () => {
		const kitchenSection = document.getElementById('paper_sec');
		kitchenSection.scrollIntoView({ behavior: 'smooth' });
	};

	return (
		<Fragment>
			<Navbar />
			<Cover coverImageSrc={product_cover} title="Products" subTitle="We want you to know all about us!" />
			<section className="product_sec first_sec">
				<div className="product_div">
					<div className="product_card card1" onClick={scrollToKitchen}>
						<img src={kitchen_img} alt="" loading="lazy" />
						<div className="heading-cover heading1">
							<h1 className="textile_head">Kitchen Equipments</h1>
						</div>
					</div>
					<img className="leaf" src={leaf} alt="" loading="lazy" />
					<div className="product_card card2" onClick={scrollToCooking}>
						<img src={cooking_img} alt="" loading="lazy" />
						<div className="heading-cover heading2">
							<h1 className="furniture_head">Cooking Equipments</h1>
						</div>
					</div>
					<img className="leaf" src={leaf} alt="" loading="lazy" />
					<div className="product_card card3" onClick={scrollToFnb}>
						<img src={fnb_img} alt="" loading="lazy" />
						<div className="heading-cover heading3">
							<h1 className="furniture_head">F&B Products</h1>
						</div>
					</div>
				</div>
				<div className="product_tagline">
					<img className="leaf2" src={leaf2} alt="" loading="lazy" />
					<h2>One Stop solution to all your hotel needs</h2>
					<img className="leaf2" src={leaf3} alt="" loading="lazy" />
				</div>
				<div className="product_div">
					<div className="product_card card3" onClick={scrollToHousekeeping}>
						<img src={housekeeping_img} alt="" loading="lazy" />
						<div className="heading-cover heading3">
							<h1 className="textile_head">Housekeeping</h1>
						</div>
					</div>
					<img className="leaf3" src={leaf4} alt="" loading="lazy" />
					<div className="product_card card2" onClick={scrollToLineware}>
						<img src={lineware_img} alt="" loading="lazy" />
						<div className="heading-cover heading2">
							<h1 className="furniture_head">Linenware</h1>
						</div>
					</div>
					<img className="leaf3" src={leaf4} alt="" loading="lazy" />
					<div className="product_card card1" onClick={scrollToPaper}>
						<img src={paper_img} alt="" loading="lazy" />
						<div className="heading-cover heading1">
							<h1 className="furniture_head">Disposables</h1>
						</div>
					</div>
				</div>
			</section>
			<section className="product_sec sec_back" id="kitchen_sec">
				<div className="prod_container main-img-1">
					<img src={kitchen_img} alt="" loading="lazy" />
					<div className="prod_content">
						<h1>Commercial Kitchen Equipments</h1>
						<p>
							Hotelerre prides itself on providing top-tier commercial kitchen equipment, catering to the
							diverse needs of culinary professionals. From industrial-grade ovens and stoves to sleek
							refrigeration units and ergonomic prep stations, our comprehensive selection ensures
							efficiency and reliability in every kitchen. With a commitment to quality and innovation,
							Hotelerre equips businesses with the tools they need to thrive in the demanding
							foodservice industry.
						</p>
					</div>
				</div>
				<ProductCarousel images={product_img} />
			</section>
			<section className="product_sec sec_back" id="cooking_sec">
				<div className="prod_container prod_reverse main-img-2">
					<img src={cooking_img} alt="" loading="lazy" />
					<div className="prod_content content_reverse">
						<h1>Cooking Equipments</h1>
						<p className="p_furniture">
							Hotelerre specializes in supplying high-quality commercial cooking equipment tailored to the
							needs of professional kitchens. From versatile grills and fryers to precision-controlled
							induction cooktops and powerful range hoods, our range of products is designed to elevate
							culinary operations. With a focus on durability, performance, and efficiency, Hotelerre
							equips businesses with the tools to achieve excellence in food preparation while maintaining
							the highest standards of safety and reliability.
						</p>
					</div>
				</div>
				<ProductCarousel images={product_img2} />
			</section>
			<section className="product_sec sec_back" id="fnb_sec">
				<div className="prod_container main-img-1">
					<img src={fnb_img} alt="" loading="lazy" />
					<div className="prod_content">
						<h1>F&B Products</h1>
						<p>
							Hotelerre takes pride in supplying top-of-the-line cutlery and crockery to elevate dining
							experiences in the hospitality industry. Our collection features an exquisite range of
							durable and stylish utensils, plates, bowls, and glassware crafted to meet the highest
							standards of quality and design. Whether it's fine dining establishments or bustling cafes,
							Hotelerre ensures that every piece not only enhances the presentation of meals but also
							withstands the rigors of commercial use. With a commitment to excellence and customer
							satisfaction, Hotelerre is the go-to choice for establishments seeking premium
							tableware solutions.
						</p>
					</div>
				</div>
				<ProductCarousel images={product_img3} />
			</section>
			<section className="product_sec sec_back" id="housekeeping_sec">
				<div className="prod_container prod_reverse main-img-2">
					<img src={housekeeping_img} alt="" loading="lazy" />
					<div className="prod_content content_reverse">
						<h1>Housekeeping</h1>
						<p className="p_furniture">
							Hotelerre is dedicated to providing top-notch housekeeping supplies to ensure cleanliness
							and comfort in hospitality establishments. Our comprehensive range includes high-quality
							cleaning chemicals, durable equipment, and ergonomic tools designed to streamline
							housekeeping operations. From eco-friendly cleaning solutions to innovative vacuum cleaners
							and ergonomic carts, Hotelerre offers products that meet the rigorous demands of the
							industry while prioritizing efficiency and sustainability. With Hotelerre, hospitality
							businesses can trust in reliable solutions that maintain the highest standards of hygiene
							and guest satisfaction.
						</p>
					</div>
				</div>
				<ProductCarousel images={product_img4} />
			</section>
			<section className="product_sec sec_back" id="lineware_sec">
				<div className="prod_container main-img-1">
					<img src={lineware_img} alt="" loading="lazy" />
					<div className="prod_content">
						<h1>Linenware</h1>
						<p>
							Hotelerre specializes in supplying premium linenware to elevate the guest experience in
							hospitality establishments. Our extensive collection includes luxurious bed linens, plush
							towels, elegant tablecloths, and durable napkins crafted from high-quality materials to
							ensure both comfort and durability. With a focus on impeccable craftsmanship and attention
							to detail, Hotelerre provides linens that enhance the ambiance of hotels, restaurants, and
							spas. From classic designs to modern styles, our linenware offerings cater to the diverse
							needs of the industry, guaranteeing a touch of luxury and sophistication for every guest.
						</p>
					</div>
				</div>
				<ProductCarousel images={product_img5} />
			</section>
			<section className="product_sec sec_back" id="paper_sec">
				<div className="prod_container prod_reverse main-img-2">
					<img src={paper_img} alt="" loading="lazy" />
					<div className="prod_content content_reverse">
						<h1>Paper Napkins and Disposables</h1>
						<p className="p_furniture">
							Hotelerre is your trusted source for premium paper napkins and disposable supplies, offering
							a wide range of products to meet the needs of hospitality and foodservice businesses. Our
							selection includes high-quality paper napkins, disposable utensils, cups, and plates, all
							designed for convenience without compromising on quality. Whether it's for a casual dining
							experience or an upscale event, Hotelerre ensures that every product maintains the highest
							standards of hygiene and functionality. With eco-friendly options available, our disposable
							supplies offer a sustainable solution for businesses looking to reduce their environmental
							footprint while providing exceptional service to their customers.
						</p>
					</div>
				</div>
				<ProductCarousel images={product_img6} />
			</section>
			<Footer />
		</Fragment>
	);
};

export default Products;
