import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import './Contact.css';
import Cover from '../../components/Cover/Cover';
import contact_cover from '../../assets/images/Contact/connect_cover.jpg';
import Footer from '../../components/Footer/Footer';
import { FaInstagram } from 'react-icons/fa';
import { FaFacebook } from 'react-icons/fa6';

const Contact = () => {
	return (
		<Fragment>
			<Navbar />
			<Cover coverImageSrc={contact_cover} title="Let's Connect" subTitle="Get in touch with us!" />
			<section className="vendor_sec getintouch" id="touch_sec">
				<div class="getintouch_whole">
					<div className="getintouch_heading">
						<h1>GET IN TOUCH</h1>
					</div>
					<div className="getintouch_content">
						<table>
							<tr>
								<th>
									<p>Address:</p>
								</th>
								<td>
									<p>
										<a href="mailto:hotelerre@gmail.com" target="_blank" rel="noreferrer">
											30, NRI complex, Gk-4, Delhi -110019, India
										</a>
									</p>
									{/* <p>127, Arihant Nagar, Punjabi Bagh West, New Delhi - 110026, India</p> */}
								</td>
							</tr>
							<tr>
								<th>
									<p>Phone:</p>
								</th>
								<td>
									{/* <p>+91-11-47 02 78 79</p> */}
									<p>
										<a href="tel:+91 8010836633">+91 8010836633</a>
									</p>
								</td>
							</tr>
							<tr>
								<th>
									<p>Email:</p>
								</th>
								<td>
									<p>
										<a href="mailto:hotelerre@gmail.com">hotelerre@gmail.com</a>
									</p>
									{/* <p>info@sapphiresourcings.com</p> */}
								</td>
							</tr>
							<tr>
								<th>
									<p>Our Social Handles:</p>
								</th>
								<td>
									<a
										rel="noreferrer"
										className="links"
										target="_blank"
										href="https://www.instagram.com/hotelerre01?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
									>
										<FaInstagram />
									</a>
									<a
										rel="noreferrer"
										className="links"
										target="_blank"
										href="https://www.facebook.com/luxuryhotelsupplies?mibextid=ibOpuV"
									>
										<FaFacebook />
									</a>
								</td>
							</tr>
						</table>
					</div>
				</div>
			</section>
			<section className="vendor_sec last-sec">
				<div className="apply-now">
					<a
						rel="noreferrer"
						target="_blank"
						href="https://drive.google.com/file/d/14j9Y470typHJ5XoeWH5Nz6vYUD4yP67k/view?usp=share_link"
					>
						CLICK HERE TO DOWNLOAD OUR CATALOGUE NOW!
					</a>
				</div>
			</section>
			<Footer />
		</Fragment>
	);
};

export default Contact;
