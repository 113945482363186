// import why1 from '../assets/images/Home/Why/why1.jpg';
import why1 from '../assets/images/Home/Why/why1.jpg';
import why2 from '../assets/images/Home/Why/why2.webp';
import why3 from '../assets/images/Home/Why/why3.jpg';
import why4 from '../assets/images/Home/Why/why4.jpg';
import why5 from '../assets/images/Home/Why/why5.jpg';
import client1 from '../assets/images/Home/Clients/roseate.jpg';
import client2 from '../assets/images/Home/Clients/taj.jpg';
import client3 from '../assets/images/Home/Clients/crown.jpeg';
import client4 from '../assets/images/Home/Clients/itc.jpeg';
import client5 from '../assets/images/Home/Clients/hyatt.webp';
import client6 from '../assets/images/Home/Clients/itc-m.png';
import client7 from '../assets/images/Home/Clients/imperfecto.jpeg';
import client8 from '../assets/images/Home/Clients/mkt.jpeg';
import client9 from '../assets/images/Home/Clients/infinity.jpeg';
import client10 from '../assets/images/Home/Clients/river.jpeg';
import client11 from '../assets/images/Home/Clients/ananta.jpg';
import client12 from '../assets/images/Home/Clients/leela.jpg';

import brand1 from '../assets/images/Home/Brands/brand1.jpg';
import brand2 from '../assets/images/Home/Brands/brand2.png';
import brand3 from '../assets/images/Home/Brands/brand3.jpg';
import brand4 from '../assets/images/Home/Brands/brand4.jpg';
import brand5 from '../assets/images/Home/Brands/brand5.png';
import brand6 from '../assets/images/Home/Brands/brand6.png';
import brand7 from '../assets/images/Home/Brands/brand7.png';
import brand8 from '../assets/images/Home/Brands/brand8.jpg';
import brand9 from '../assets/images/Home/Brands/brand9.png';
import brand10 from '../assets/images/Home/Brands/brand10.png';
import brand11 from '../assets/images/Home/Brands/brand11.jpg';
import brand12 from '../assets/images/Home/Brands/brand12.jpg';
import brand13 from '../assets/images/Home/Brands/brand13.png';
import brand14 from '../assets/images/Home/Brands/brand14.jpg';
import brand15 from '../assets/images/Home/Brands/brand15.jpg';
import brand16 from '../assets/images/Home/Brands/brand16.jpg';
import brand17 from '../assets/images/Home/Brands/brand17.jpg';

import kitchen1 from '../assets/images/products/kitchen/img1.jpg';
import kitchen2 from '../assets/images/products/kitchen/img2.jpg';
import kitchen3 from '../assets/images/products/kitchen/img3.jpg';
import kitchen4 from '../assets/images/products/kitchen/img4.jpg';
import kitchen5 from '../assets/images/products/kitchen/img5.jpg';
import kitchen6 from '../assets/images/products/kitchen/img6.jpg';
import kitchen7 from '../assets/images/products/kitchen/img7.jpg';
import kitchen8 from '../assets/images/products/kitchen/img8.jpg';
import kitchen9 from '../assets/images/products/kitchen/img9.jpg';
import kitchen10 from '../assets/images/products/kitchen/img10.jpg';
import kitchen11 from '../assets/images/products/kitchen/img11.jpg';
// import kitchen12 from '../assets/images/products/kitchen/img12.jpg';
// import kitchen13 from '../assets/images/products/kitchen/img13.jpg';
import kitchen14 from '../assets/images/products/kitchen/img14.jpg';
import kitchen15 from '../assets/images/products/kitchen/img15.jpg';
// import kitchen16 from '../assets/images/products/kitchen/img16.jpg';
// import kitchen17 from '../assets/images/products/kitchen/img17.jpg';

import cooking1 from '../assets/images/products/cooking/img1.jpg';
import cooking2 from '../assets/images/products/cooking/img2.jpg';
import cooking3 from '../assets/images/products/cooking/img3.jpg';
import cooking4 from '../assets/images/products/cooking/img4.jpg';
import cooking5 from '../assets/images/products/cooking/img6.jpg';
import cooking6 from '../assets/images/products/cooking/img7.jpg';
import cooking7 from '../assets/images/products/cooking/img8.jpg';
import cooking8 from '../assets/images/products/cooking/img9.jpg';
import cooking9 from '../assets/images/products/cooking/img10.jpg';
import cooking10 from '../assets/images/products/cooking/img11.jpg';
import cooking11 from '../assets/images/products/cooking/img12.jpg';
import cooking12 from '../assets/images/products/cooking/img13.jpg';
import cooking13 from '../assets/images/products/cooking/img14.jpg';
import cooking14 from '../assets/images/products/cooking/img15.jpg';
import cooking15 from '../assets/images/products/cooking/img16.jpg';
import cooking16 from '../assets/images/products/cooking/img17.jpg';
import cooking17 from '../assets/images/products/cooking/img18.jpg';
import cooking18 from '../assets/images/products/cooking/img19.jpg';
import cooking19 from '../assets/images/products/cooking/img20.jpg';
import cooking20 from '../assets/images/products/cooking/img21.jpg';
import cooking21 from '../assets/images/products/cooking/img22.jpg';
import cooking22 from '../assets/images/products/cooking/img5.jpg';
import cooking23 from '../assets/images/products/cooking/img23.jpg';
import cooking24 from '../assets/images/products/cooking/img24.jpg';
import cooking25 from '../assets/images/products/cooking/img25.jpg';
import cooking26 from '../assets/images/products/cooking/img26.jpg';
import cooking27 from '../assets/images/products/cooking/img27.jpg';
import cooking28 from '../assets/images/products/cooking/img28.jpg';
import cooking29 from '../assets/images/products/cooking/img29.jpg';
import cooking30 from '../assets/images/products/cooking/img30.jpg';
import cooking31 from '../assets/images/products/cooking/img31.jpg';
import cooking32 from '../assets/images/products/cooking/img32.jpg';
import cooking33 from '../assets/images/products/cooking/img33.jpg';
import cooking34 from '../assets/images/products/cooking/img34.jpg';
import cooking35 from '../assets/images/products/cooking/img35.jpg';
import cooking36 from '../assets/images/products/cooking/img36.jpg';

import fnb1 from '../assets/images/products/fnb/img1.jpg';
import fnb2 from '../assets/images/products/fnb/img2.jpg';
import fnb3 from '../assets/images/products/fnb/img3.jpg';
import fnb4 from '../assets/images/products/fnb/img4.jpg';
import fnb5 from '../assets/images/products/fnb/img5.jpg';
import fnb6 from '../assets/images/products/fnb/img6.jpg';

import houskeeping1 from '../assets/images/products/housekeeping/img1.jpg';
import houskeeping2 from '../assets/images/products/housekeeping/img2.jpg';
import houskeeping3 from '../assets/images/products/housekeeping/img3.jpg';
import houskeeping4 from '../assets/images/products/housekeeping/img4.jpg';
import houskeeping5 from '../assets/images/products/housekeeping/img5.jpg';
import houskeeping6 from '../assets/images/products/housekeeping/img6.png';
import houskeeping7 from '../assets/images/products/housekeeping/img7.jpg';
import houskeeping8 from '../assets/images/products/housekeeping/img8.jpg';
import houskeeping9 from '../assets/images/products/housekeeping/img9.jpg';
import houskeeping10 from '../assets/images/products/housekeeping/img10.jpg';
import houskeeping11 from '../assets/images/products/housekeeping/img11.jpg';
import houskeeping12 from '../assets/images/products/housekeeping/img12.jpg';
import houskeeping13 from '../assets/images/products/housekeeping/img13.jpg';
import houskeeping14 from '../assets/images/products/housekeeping/img14.jpg';
import houskeeping15 from '../assets/images/products/housekeeping/img15.jpg';
import houskeeping16 from '../assets/images/products/housekeeping/img16.jpg';
import houskeeping17 from '../assets/images/products/housekeeping/img17.jpg';
import houskeeping18 from '../assets/images/products/housekeeping/img18.jpg';

import lineware1 from '../assets/images/products/lineware/img1.jpg';
import lineware2 from '../assets/images/products/lineware/img2.jpg';
import lineware3 from '../assets/images/products/lineware/img3.jpg';
import lineware4 from '../assets/images/products/lineware/img4.jpg';
import lineware5 from '../assets/images/products/lineware/img5.jpg';
import lineware6 from '../assets/images/products/lineware/img6.png';

import paper1 from '../assets/images/products/paper/img1.jpg';
import paper2 from '../assets/images/products/paper/img2.jpg';
import paper3 from '../assets/images/products/paper/img3.jpg';
import paper4 from '../assets/images/products/paper/img4.jpg';
import paper5 from '../assets/images/products/paper/img5.jpg';
import paper6 from '../assets/images/products/paper/img6.jpg';
import paper7 from '../assets/images/products/paper/img7.jpg';
import paper8 from '../assets/images/products/paper/img8.jpg';
import paper9 from '../assets/images/products/paper/img9.jpg';
import paper10 from '../assets/images/products/paper/img10.jpg';
import paper11 from '../assets/images/products/paper/img11.jpg';
import paper12 from '../assets/images/products/paper/img12.jpg';
import paper13 from '../assets/images/products/paper/img13.jpg';
import paper14 from '../assets/images/products/paper/img14.jpg';
import paper15 from '../assets/images/products/paper/img15.jpg';
import paper16 from '../assets/images/products/paper/img16.jpg';
import paper17 from '../assets/images/products/paper/img17.jpg';
import paper18 from '../assets/images/products/paper/img18.jpg';

const product_img = [
	{
		image: kitchen1
	},
	{
		image: kitchen2
	},
	{
		image: kitchen3
	},
	{
		image: kitchen4
	},
	{
		image: kitchen5
	},
	{
		image: kitchen6
	},
	{
		image: kitchen7
	},
	{
		image: kitchen8
	},
	{
		image: kitchen9
	},
	{
		image: kitchen10
	},
	{
		image: kitchen11
	},
	// {
	// 	image: kitchen12
	// },
	// {
	// 	image: kitchen13
	// },
	{
		image: kitchen14
	},
	{
		image: kitchen15
	}
	// {
	// 	image: kitchen16
	// },
	// {
	// 	image: kitchen17
	// }
];
const product_img2 = [
	{
		image: cooking1
	},
	{
		image: cooking2
	},
	{
		image: cooking3
	},
	{
		image: cooking4
	},
	{
		image: cooking5
	},
	{
		image: cooking6
	},
	{
		image: cooking7
	},
	{
		image: cooking8
	},
	{
		image: cooking9
	},
	{
		image: cooking10
	},
	{
		image: cooking11
	},
	{
		image: cooking12
	},
	{
		image: cooking13
	},
	{
		image: cooking14
	},
	{
		image: cooking15
	},
	{
		image: cooking16
	},
	{
		image: cooking17
	},
	{
		image: cooking18
	},
	{
		image: cooking19
	},
	{
		image: cooking20
	},
	{
		image: cooking21
	},
	{
		image: cooking22
	},
	{
		image: cooking23
	},
	{
		image: cooking24
	},
	{
		image: cooking25
	},
	{
		image: cooking26
	},
	{
		image: cooking27
	},
	{
		image: cooking28
	},
	{
		image: cooking29
	},
	{
		image: cooking30
	},
	{
		image: cooking31
	},
	{
		image: cooking32
	},
	{
		image: cooking33
	},
	{
		image: cooking34
	},
	{
		image: cooking35
	},
	{
		image: cooking36
	}
];

const product_img3 = [
	{
		image: fnb1
	},
	{
		image: fnb2
	},
	{
		image: fnb3
	},
	{
		image: fnb4
	},
	{
		image: fnb5
	},
	{
		image: fnb6
	}
];

const product_img4 = [
	{
		image: houskeeping1
	},
	{
		image: houskeeping2
	},
	{
		image: houskeeping3
	},
	{
		image: houskeeping4
	},
	{
		image: houskeeping5
	},
	{
		image: houskeeping6
	},
	{
		image: houskeeping7
	},
	{
		image: houskeeping8
	},
	{
		image: houskeeping9
	},
	{
		image: houskeeping10
	},
	{
		image: houskeeping11
	},
	{
		image: houskeeping12
	},
	{
		image: houskeeping13
	},
	{
		image: houskeeping14
	},
	{
		image: houskeeping15
	},
	{
		image: houskeeping16
	},
	{
		image: houskeeping17
	},
	{
		image: houskeeping18
	}
];

const product_img5 = [
	{
		image: lineware1
	},
	{
		image: lineware2
	},
	{
		image: lineware3
	},
	{
		image: lineware4
	},
	{
		image: lineware5
	},
	{
		image: lineware6
	}
];

const product_img6 = [
	{
		image: paper1
	},
	{
		image: paper2
	},
	{
		image: paper3
	},
	{
		image: paper4
	},
	{
		image: paper5
	},
	{
		image: paper6
	},
	{
		image: paper7
	},
	{
		image: paper8
	},
	{
		image: paper9
	},
	{
		image: paper10
	},
	{
		image: paper11
	},
	{
		image: paper12
	},
	{
		image: paper13
	},
	{
		image: paper14
	},
	{
		image: paper15
	},
	{
		image: paper16
	},
	{
		image: paper17
	},
	{
		image: paper18
	}
];

const why_data = [
	{
		name: 'why',
		image: why1,
		heading: 'Excellent service',
		paragraph: ` Excellence in service is our utmost priority. We are dedicated to providing top-notch solutions to meet the needs of our valued hotel clients, ensuring their satisfaction and success`,
		catalog: ''
	},
	{
		name: 'why',
		image: why2,
		heading: 'Premium quality',
		paragraph: `Premium quality is our hallmark. We are committed to delivering top-tier products and services, ensuring that our clients receive nothing but the best for their establishments.`,
		catalog: ''
	},
	{
		name: 'why',
		image: why3,
		heading: 'Professional Team',
		paragraph: `We are driven by a dedicated and highly professional team. With their expertise, we ensure our clients receive top-notch service and support, tailored to meet their unique needs and expectations.`,
		catalog: ''
	},
	{
		name: 'why',
		image: why4,
		heading: 'Competitive Pricing',
		paragraph: `We take pride in offering competitive prices. We are committed to providing cost-effective solutions without compromising on quality, making us the ideal choice for hoteliers seeking value and excellence.`,
		catalog: ''
	},
	{
		name: 'why',
		image: why5,
		heading: 'On Time delivery',
		paragraph: `Punctuality is our promise. We prioritize on-time delivery to ensure our clients receive their orders exactly when they need them. Count on us for reliable and timely service, keeping your hotel operations running smoothly.`,
		catalog: ''
	}
];
const client_data = [
	{
		name: 'client',
		image: client1,
		heading: 'Roseate House, Aerocity'
	},
	{
		name: 'client',
		image: client2,
		heading: 'Taj, Norbu The Montanna, Dharamshala'
	},
	{
		name: 'client',
		image: client3,
		heading: 'Crowne Plaza'
	},
	{
		name: 'client',
		image: client4,
		heading: 'ITC Mughal, Agra'
	},
	{
		name: 'client',
		image: client5,
		heading: 'Hyatt Regency'
	},
	{
		name: 'client',
		image: client6,
		heading: 'ITC Maurya'
	},
	{
		name: 'client',
		image: client7,
		heading: 'Imperfecto'
	},
	{
		name: 'client',
		image: client8,
		heading: 'MKT, Delhi'
	},
	{
		name: 'client',
		image: client9,
		heading: 'Infinty Bistro'
	},
	{
		name: 'client',
		image: client10,
		heading: 'River Retreat'
	},
	{
		name: 'client',
		image: client11,
		heading: 'Ananta Spa & Resort'
	},
	{
		name: 'client',
		image: client12,
		heading: 'The Leela'
	}
];

const brand_data = [
	{
		name: 'brand',
		image: brand1,
		heading: 'Roseate House, Aerocity'
	},
	{
		name: 'brand',
		image: brand2,
		heading: 'Taj, Norbu The Montanna, Dharamshala'
	},
	{
		name: 'brand',
		image: brand3,
		heading: 'Crowne Plaza'
	},
	{
		name: 'brand',
		image: brand4,
		heading: 'ITC Mughal, Agra'
	},
	{
		name: 'brand',
		image: brand5,
		heading: 'Hyatt Regency'
	},
	{
		name: 'brand',
		image: brand6,
		heading: 'ITC Maurya'
	},
	{
		name: 'brand',
		image: brand7,
		heading: 'Imperfecto'
	},
	{
		name: 'brand',
		image: brand8,
		heading: 'MKT, Delhi'
	},
	{
		name: 'brand',
		image: brand9,
		heading: 'Infinty Bistro'
	},
	{
		name: 'brand',
		image: brand10,
		heading: 'River Retreat'
	},
	{
		name: 'brand',
		image: brand11,
		heading: 'Ananta Spa & Resort'
	},
	{
		name: 'brand',
		image: brand12,
		heading: 'The Leela'
	},
	{
		name: 'brand',
		image: brand13,
		heading: 'The Leela'
	},
	{
		name: 'brand',
		image: brand14,
		heading: 'The Leela'
	},
	{
		name: 'brand',
		image: brand15,
		heading: 'The Leela'
	},
	{
		name: 'brand',
		image: brand16,
		heading: 'The Leela'
	},
	{
		name: 'brand',
		image: brand17,
		heading: 'The Leela'
	}
];

export {
	why_data,
	client_data,
	brand_data,
	product_img,
	product_img2,
	product_img3,
	product_img4,
	product_img5,
	product_img6
};
