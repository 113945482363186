import React, { useState, useEffect } from 'react';
import './ProductSlider.css';
import { FaCircleArrowDown, FaCircleArrowUp } from 'react-icons/fa6';

const ProductSlider = () => {
	const slides = [
		{
			title: 'Houskeeping',
			description: `Hotelerre provides top-quality housekeeping supplies for hospitality, ensuring cleanliness and comfort. Our range includes eco-friendly solutions and innovative tools for efficient operations. Trust us for reliable, hygienic solutions.`
		},
		{
			title: 'F&B Products',
			description: `Hotelerre offers premium cutlery and crockery to enhance dining experiences. Our collection includes stylish and durable utensils, plates, bowls, and glassware crafted to the highest standards. From fine dining to casual cafes, Hotelerre ensures both presentation and durability.`
		},
		{
			title: 'Linenware',
			description: `Hotelerre offers top-tier linenware for luxury hospitality. From bed linens to tablecloths, our quality materials elevate guest experiences with comfort and sophistication.`
		},
		{
			title: 'Paper Napkins and Disposables',
			description: `Hotelerre offers top-tier paper napkins and disposables for hospitality and foodservice needs. From high-quality napkins to eco-friendly utensils and cups, we prioritize convenience and quality. Whether for casual dining or upscale events, our products maintain hygiene and functionality standards while reducing environmental impact.`
		},
		{
			title: 'Cooking Equipments',
			description:
				'Hotelerre excels in providing premium commercial cooking equipment tailored to professional kitchens. Our versatile range, from grills to range hoods, elevates culinary operations with precision and efficiency. With a focus on durability and safety, we equip businesses to achieve excellence in food preparation.'
		},
		{
			title: 'Kitchen Equipments',
			description:
				'Hotelerre offers premium kitchen equipment for culinary experts. From robust ovens to stylish refrigeration units, our range ensures efficiency and reliability. Committed to quality and innovation, we provide businesses with the tools to excel in the food service industry.'
		}
	];

	const [ activeSlideIndex, setActiveSlideIndex ] = useState(0);
	const slidesLength = slides.length; // The number of slides

	useEffect(
		() => {
			const sliderHeight = document.querySelector('.slider-container').clientHeight;
			const slideRight = document.querySelector('.right-slide');
			const slideLeft = document.querySelector('.left-slide');

			slideLeft.style.top = `-${(slidesLength - 1) * 80}vh`;

			slideRight.style.transform = `translateY(-${activeSlideIndex * sliderHeight}px)`;
			slideLeft.style.transform = `translateY(${activeSlideIndex * sliderHeight}px)`;
			// const leftSlideTransform = activeSlideIndex === 0 ? '0' : `${(activeSlideIndex - 1) * sliderHeight}px`;
			// slideLeft.style.transform = `translateY(${leftSlideTransform})`;
		},
		[ activeSlideIndex, slidesLength ]
	);

	const changeSlide = (direction) => {
		if (direction === 'up') {
			setActiveSlideIndex((prevIndex) => (prevIndex + 1) % slidesLength);
		} else if (direction === 'down') {
			setActiveSlideIndex((prevIndex) => (prevIndex === 0 ? slidesLength - 1 : prevIndex - 1));
		}
	};

	return (
		<div className="slider-container">
			{/* Left Slide */}
			<div className="left-slide">
				{slides.map((slide, index) => (
					<div key={index} className={`product-slider-content ${index === activeSlideIndex ? 'active' : ''}`}>
						<h1>{slide.title}</h1>
						<p>{slide.description}</p>
					</div>
				))}
			</div>
			{/* Right Slide */}
			<div className="right-slide">
				<div className="back-img" />
				<div className="back-img2" />
				<div className="back-img3" />
				<div className="back-img4" />
				<div className="back-img5" />
				<div className="back-img6" />
				{/* Add background images or images from demo1, demo2, etc. */}
			</div>
			{/* Action Buttons */}
			<div className="action-buttons">
				<button className="down-button" onClick={() => changeSlide('down')}>
					{/* <i className="fas fa-arrow-down" /> */}
					<FaCircleArrowUp />
				</button>
				<button className="up-button" onClick={() => changeSlide('up')}>
					<FaCircleArrowDown />
				</button>
			</div>
		</div>
	);
};

export default ProductSlider;
