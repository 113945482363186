import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../assets/images/logo_header.png';

function Navbar() {
	const [ click, setClick ] = useState(false);
	const [ showNavbar, setShowNavbar ] = useState(false);
	const [ prevScrollPos, setPrevScrollPos ] = useState(0);
	const [ atTop, setAtTop ] = useState(true);

	const handleScroll = useCallback(
		() => {
			const currentScrollPos = window.pageYOffset;
			const isScrollingDown = currentScrollPos > prevScrollPos;
			setShowNavbar(!isScrollingDown || currentScrollPos < 10); // Show navbar if scrolling up or at the top
			setPrevScrollPos(currentScrollPos);
			if (currentScrollPos === 0) {
				setShowNavbar(false);
				setAtTop(true);
			} else {
				setAtTop(false);
			}
		},
		[ prevScrollPos ]
	);

	useEffect(
		() => {
			window.addEventListener('scroll', handleScroll);
			return () => {
				window.removeEventListener('scroll', handleScroll);
			};
		},
		[ prevScrollPos, handleScroll ]
	);

	const handleClick = () => setClick(!click);

	const handleLogoClick = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};
	const handleNavLinkClick = () => {
		if (!atTop) {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}
		setClick(false); // Close the mobile menu if it's open
	};

	return (
		<Fragment>
			{/* <nav className={`navbar ${showNavbar ? 'active-scroll' : ''}`}> */}
			<nav className={`navbar ${showNavbar ? 'active-scroll' : ''} ${atTop ? 'atTop' : ''}`}>
				{/* <div className="nav-container"> */}
				<div className="nav-logo">
					<NavLink exact to="/" onClick={handleLogoClick}>
						<img src={logo} alt="Logo" />
					</NavLink>
				</div>
				{/* <NavLink exact to="/" className="nav-logo" onClick={handleLogoClick}>
					<img src={logo} alt="Logo" />
				</NavLink> */}

				<ul className={click ? 'nav-menu active' : 'nav-menu'}>
					<li className="nav-item">
						<NavLink
							exact
							to="/"
							activeClassName="active"
							className="nav-links"
							onClick={handleNavLinkClick}
						>
							Home
						</NavLink>
					</li>
					<li className="nav-item">
						<NavLink
							exact
							to="/about"
							activeClassName="active"
							className="nav-links"
							onClick={handleNavLinkClick}
						>
							Who are we?
						</NavLink>
					</li>

					<li className="nav-item">
						<NavLink
							exact
							to="/products"
							activeClassName="active"
							className="nav-links"
							onClick={handleNavLinkClick}
						>
							Products
						</NavLink>
					</li>
					<li className="nav-item">
						<NavLink
							exact
							to="/contact"
							activeClassName="active"
							className="nav-links contact"
							onClick={handleNavLinkClick}
						>
							Let's Connect
						</NavLink>
					</li>
				</ul>
				<div className={`nav-icon ${atTop ? 'atTopHam' : ''}`} onClick={handleClick}>
					{/* <i className={click ? 'fas fa-times' : 'fas fa-bars'} /> */}
					{!click && <MenuIcon className="icon" />}
					{click && <CloseIcon className="icon" />}
				</div>
			</nav>
		</Fragment>
	);
}

export default Navbar;
